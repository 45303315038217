import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Application from "../components/helmet";
import Layout from "../components/layout";
import About from "../components/about";
import Resources from "../components/resources";
import Events from "../components/events";
import ContactUs from "../components/contact-us";
import Footer from "../components/footer"; 
import "../styles/reset.css";
import "../styles/style.css";

const Index = ({ data }) => {
  console.log(data)
  setTimeout(function(){
    $(".carousel-item:first").addClass("active");
    $(".top-menu li a,.bottom-menu li a").click(function () {
      $(".top-menu li a,.bottom-menu li a").removeClass("active");
      // $(".tab").addClass("active"); // instead of this do the below 
      $(this).addClass("active");
  });
  //Cars Carousel
  $('.carousel.carousel-multi-item.v-2 .carousel-item').each(function () {
      var next = $(this).next();
      if (!next.length) {
          next = $(this).siblings(':first');
      }
      next.children(':first-child').clone().appendTo($(this));

      for (var i = 0; i < 4; i++) {
          next = next.next();
          if (!next.length) {
              next = $(this).siblings(':first');
          }
          next.children(':first-child').clone().appendTo($(this));
      }
  });
  }, 2000)
  
  return (
    
    <div className="content">
      <Application></Application>
      <div className="container-fluid">        
        <Layout data={data.allKontentItemLayout.nodes[0].elements}></Layout>        
        <About data={data.allKontentItemAboutUs.nodes[0].elements}></About>
        <Events  data={data.allKontentItemEventHeader.nodes[0].elements}></Events>
        <Resources data={data.allKontentItemResources.nodes[0].elements}></Resources>
        <ContactUs data={data.allKontentItemContact.nodes[0].elements}></ContactUs>
        <Footer data={data.allKontentItemLayout.nodes[0].elements}></Footer>
      </div>
    </div>
   
  );
};

export default Index;



Index.propTypes = {
  data: PropTypes.object
};
export const query = graphql`
query LayoutContentQuery{
  allKontentItemLayout {
    nodes {
      elements {
        about {
          value
        }
        contact {
          value
        }
        events {
          value
        }
        resources {
          value
        }
        logo_image {
          value {
            url
          }
        }
        logo_text_1 {
          value
        }
        logo_text_2 {
          value
        }
        home {
          value
        }
        facebooklink {
          value
        }
        count_members_text {
          value
        }
        lookingfortext {
          value
        }
      }
    }
  }  
    allKontentItemAboutUs {
      nodes {
        elements {
          about_text {
            value
          }
        }
      }
    
  }
  allKontentItemResources {
    nodes {
      elements {
        text {
          value
        }
        readmorelink {
          value
        }
      }
    }
  }
  allKontentItemContact {
    nodes {
      elements {
        email_text {
          value
        }
        emailid {
          value
        }
        facebook_text {
          value
        }
      }
    }
  }
  allKontentItemEventHeader {
    nodes {
      elements {
        click_text {
          value
        }
        name {
          value
        }
        events {
          linked_items {
            ... on KontentItemEvents {
              id
              elements {
                image {
                  value {
                    url
                  }
                }
                eventname {
                  value
                }
                description {
                  value
                }
                addlink {
                  value
                }
              }
            }
          }
        }
        events_info_text {
          value
        }
      }
    }
  }
  }
`;