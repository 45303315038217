import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import IconLatter from "../img/icon-letter.png";
import IconFB2 from "../img/icon-fb-2.png";
const ContactUs = props =>{ 
  
  return(
    <section id="contact-us" className="contact-us tesla-site-wrap">
  <div className="container">
    <div className="container-wrap-text">
      <div className="row no-gutters contact-us-header">
        <div className="col-md-12">
          <div className="contact-us-title">
            <h4 className="text-capitalize txt-title-dark">contact-us</h4>
          </div>
        </div>
      </div>
      <div className="row no-gutters contact-us-header">
        <div className="col-12 col-md-6">
          <ul className="contact-details-item">
            <li>
              <img
                src={IconLatter}
                className="img-fluid"
                alt="Send us an e-mail"
              />
            </li>
            <li>
            {props.data.email_text.value}{" "}
            </li>
          </ul>
          <ul className="contact-details-item">
            <li>
              <img
                src={IconFB2}
                className="img-fluid"
                alt="Send us an e-mail"
              />
            </li>
            <li>
            {props.data.emailid.value} <br /> <br />
            {props.data.facebook_text.value}
            </li>
          </ul>
        </div>
        <div className="col-12 col-md-6 form-wrap">
          <div id="form-main">
            <div id="form-div">
              <form method="POST" action="https://formspree.io/dipender@surfcode.io">
                <p className="name">
                  <input
                    name="name"
                    type="text"
                    className="validate[required,custom[onlyLetter],length[0,100]] feedback-input"
                    placeholder="Name"
                    id="name"
                  />
                </p>

                <p className="email">
                  <input
                    name="_replyto"
                    type="email"
                    className="validate[required,custom[email]] feedback-input"
                    id="email"
                    placeholder="Email"
                  />
                </p>

                <p className="text">
                  <textarea
                    name="text"
                    className="validate[required,length[6,300]] feedback-input"
                    id="message"
                    placeholder="Message"
                  ></textarea>
                </p>

                <div className="submit">
                  <input type="submit" value="Send" id="button-red"
                   onClick={e => {
                   
                  }} />
                </div>
              </form>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="clearfix"></div>
  </div>
</section>
);
  }
ContactUs.propTypes = {
  siteTitle: PropTypes.string
};

export default ContactUs;

