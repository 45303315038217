import React from "react";
import PropTypes from "prop-types";
import { Link ,useStaticQuery, graphql} from "gatsby";

const Header = props =>{ 
  
  return(  
        <div className="row">
          <div className="col-12 col-md-2 logo-in-header">            
         
            <a href="/" >
            <img className="img-fluid"
                src={props.data.logo_image.value[0].url}
                alt="Tesla San-Diego"
              />
              <h1>
                {props.data.logo_text_1.value}<span>{props.data.logo_text_2.value}</span>
              </h1>
            </a>
          </div>
          <div className="col-12 col-md-10">
                                <ul className="top-menu float-right">
                                    <li><a href="#about">{props.data.about.value}</a></li>
                                    <li><a href="#Events">{props.data.events.value}</a></li>
                                    <li><a href="#resources">{props.data.resources.value}</a></li>
                                    <li><a href="#contact-us">{props.data.contact.value}</a></li>
                                    <li><a target="_blank" href={props.data.facebooklink.value}>fa</a></li>
                                </ul>
                            </div>
          
        </div>
        
);
}
Header.propTypes = {
  siteTitle: PropTypes.string
};

export default Header;
