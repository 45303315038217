import React from "react";
import PropTypes from "prop-types";


const SingleEvent = props =>{ 
    const item = props.data;
  return(  
      
    <div className="carousel-item">
    <div className="col-12 col-md-4">
        <div className="card mb-2">
            <img className="rounded-circle"
                src={item.elements.image.value[0].url}
                alt="Card image cap"/>
            <div className="card-body">
                <h4 className="card-title">{item.elements.eventname.value}</h4>
                <p className="card-text">{item.elements.description.value}</p>
                <a className="btn btn-primary btn-md btn-rounded" href={item.elements.addlink.value}>Button</a>
            </div>
        </div>
    </div>
</div>
);
}
SingleEvent.propTypes = {
  siteTitle: PropTypes.string
};

export default SingleEvent;
