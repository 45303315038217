import React from "react";
import PropTypes from "prop-types";
import { Link ,useStaticQuery,StaticQuery,graphql} from "gatsby";
const Footer = props => {
  return(
    <footer>
        <section className="footer tesla-site-wrap">
            <div className="container">
                <div className="container-wrap-text">
                    <div className="row">
                        <div className="col-12">
                            <ul className="bottom-menu float-left">
                                <li><a href="#header">{props.data.home.value}</a></li>
                                <li><a href="#Events">{props.data.events.value}</a></li>
                                <li><a href="#resources">{props.data.resources.value}</a></li>
                                <li><a href="#contact-us">{props.data.contact.value}</a></li>
                                <li><a target="_blank" href={props.data.facebooklink.value}>fa</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="footer-disc tesla-site-wrap">
            <div className="container">
                <div className="container-wrap-text text-center">
                    <div className="row">
                        <div className="col-12">
                            <div className="disc-text">
                                    Copyright &copy; <span id="spanYear"><script>document.querySelector('#spanYear').textContent = (new Date()).getFullYear()</script></span>
                                    San Diego
                                    Tesla Club - All Right Reserved.
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </footer>

  );
  }
  Footer.propTypes = {
    siteTitle: PropTypes.string
  };
  export default Footer;