import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
const About = props =>{ 
    
   return(
        <section id="about" className="about tesla-site-wrap">
                <div className="container">
                    <div className="container-wrap-text">
                        <div className="row no-gutters">
                            <div className="col-md-12">
                                <div className="type-a">
                                    <h4 className="text-capitalize txt-title color-white">about</h4>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-12 txt-2columns" 
                                      dangerouslySetInnerHTML={{
                                            __html : props.data.about_text.value
                                        }}
                                       />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
);
}
About.propTypes = {
    siteTitle: PropTypes.string
  };
  
  export default About;