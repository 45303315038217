import React from "react";
import PropTypes from "prop-types";

import { StaticQuery, graphql } from "gatsby";
import Header from "../components/header";
import Logo from "../img/logo-header.png";

const Layout = props =>{ return (
  <section className="header tesla-site-wrap">
    <div className="container">
      <div className="container-wrap">        
        <Header logo={Logo} data={props.data}></Header>
        <div className="row title-counter">
      <div className="col-12 text-center">
        <h2 className="font-weight-light text-uppercase">
          {props.data.lookingfortext.value}
        </h2>
        <div className="gray-sep">&nbsp;</div>
        <p className="count-members text-uppercase font-weight-bold">{props.data.count_members_text.value}</p>
      </div>
    </div>
      </div>
    </div>
    
  
  </section>
);
}
Layout.propTypes = {
  children: PropTypes.object
};

export default Layout;
