import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import Carimage from "../img/car3.png";
const Resources = props =>{ 
   
    return(
<section id="resources" className="resources tesla-site-wrap">
                <div className="resources-wrap">
                    <div className="container">
                        <div className="container-wrap-text">
                            <div className="row no-gutters">
                                <div className="col-md-12">
                                    <div className="type-r">
                                        <h4 className="text-capitalize txt-title-dark">resources</h4>
                                    </div>
                                    <div className="row no-gutters resources-info ">
                                        <div className="col-sm-6 text-wrap">
                                            <p>
                                               {props.data.text.value}
                                            </p>
                                            <a href={props.data.readmorelink.value} className="myButton">Read More</a>
                                        </div>
                                        <div className="col-sm-6 d-none d-sm-block">
                                            <div className="img-wrapper">
                                               

                                                <img src={Carimage} className="img-fluid" alt="Resources" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
);
    }
Resources.propTypes = {
    siteTitle: PropTypes.string
  };
  
  export default Resources;