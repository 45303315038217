import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import SingleEvent from "../components/singleevent";
const Events = props =>{ 
   const eventData = props.data.events.linked_items;
   console.log(eventData)
    return(
    <section id="Events" className="events tesla-site-wrap">
                <div className="container">
                    <div className="container-wrap-text">
                        <div className="row no-gutters events-header">
                            <div className="col-md-12">
                                <div className="events-title">
                                    <h4 className="text-capitalize txt-title-dark">{props.data.name.value}</h4>
                                </div>
                                <div className="events-info">
                                    <span className="text-uppercase">{props.data.events_info_text.value}</span>
                                    <span>{props.data.click_text.value}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-12">
                                <div className="events-slider">
                                    <div id="carousel-example-multi" className="carousel slide carousel-multi-item v-2"
                                        data-ride="carousel">

                                        <div className="controls-top">
                                            <a className="btn-floating" href="#carousel-example-multi" data-slide="prev"><i
                                                    className="fas fa-chevron-left"></i></a>
                                            <a className="btn-floating" href="#carousel-example-multi" data-slide="next"><i
                                                    className="fas fa-chevron-right"></i></a>
                                        </div>

                                        <div className="carousel-inner v-2" role="listbox">
                                       {eventData.map(function(item, index){
                                           return <SingleEvent data={item} key={index}/>

                                            })}
                                            

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
);}
Events.propTypes = {
    siteTitle: PropTypes.string
  };
  
  export default Events;
